var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-aftersale-upload-img" },
    [
      _vm._m(0),
      _vm._l(_vm.list, function(item, index) {
        return _c("div", { key: index, staticClass: "list-item" }, [
          _c("div", { staticClass: "left" }, [
            _vm._v(_vm._s(item.size) + "个")
          ]),
          _c("div", { staticClass: "right" }, [_vm._v(_vm._s(item.time))])
        ])
      }),
      _c(
        "div",
        { staticClass: "loading-box" },
        [
          _vm.loading && !_vm.isShowAll
            ? _c("Button", {
                attrs: {
                  loading: "",
                  type: "default",
                  "loading-text": "加载中..."
                }
              })
            : _vm._e(),
          !_vm.loading && !_vm.isShowAll
            ? _c(
                "Button",
                { attrs: { type: "default" }, on: { click: _vm.loadMore } },
                [_vm._v("点击加载更多")]
              )
            : _vm._e(),
          _vm.isShowAll
            ? _c("Button", { attrs: { type: "default" } }, [
                _vm._v(_vm._s(_vm.list.length ? "已全部显示" : "暂无数据"))
              ])
            : _vm._e()
        ],
        1
      ),
      _c("div", { staticClass: "create-btn", on: { click: _vm.createItem } }, [
        _c("div", [_vm._v("新建")]),
        _c("div", [_vm._v("记录")])
      ])
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "table-header" }, [
      _c("div", { staticClass: "left" }, [_vm._v("探头数量")]),
      _c("div", { staticClass: "right" }, [_vm._v("上传时间")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }