require("core-js/modules/es.array.concat");

require("core-js/modules/es.array.map");

require("core-js/modules/es.regexp.exec");

require("core-js/modules/es.string.replace");

(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "vant/es/toast/style", "vant/es/toast", "vant/es/button/style", "vant/es/button", "@/utils/time"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("vant/es/toast/style"), require("vant/es/toast"), require("vant/es/button/style"), require("vant/es/button"), require("@/utils/time"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.style, global.toast, global.style, global.button, global.time);
    global.uploadImgPage = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _style, _toast, _style2, _button, _time) {
  "use strict";

  var _interopRequireDefault = require("/root/deploy/iot-product-tool/node_modules/@babel/runtime/helpers/interopRequireDefault");

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _toast = _interopRequireDefault(_toast);
  _button = _interopRequireDefault(_button);
  var _default = {
    name: "RyUploadImgPage",
    components: {
      Button: _button.default
    },
    data: function data() {
      var ossHost = this.$config.base.ossHost;
      return {
        ossHost: ossHost,
        list: [],
        isShowAll: false,
        loading: false
      };
    },
    created: function created() {
      var vm = this; // const { USER_NAME } = vm.$config.keys;
      // let name = vm.$localStorage.getItem(USER_NAME);

      var uuid = vm.$localStorage.getItem('uuid') || vm.$route.query.uuid;
      var fType = vm.$strTool.filterFactoryType(uuid);

      if (fType != 'yingwo') {
        vm.$router.replace({
          path: "404"
        });
        return;
      }

      vm.pageNo = 1;
      vm.loadList();
    },
    methods: {
      loadList: function loadList() {
        var vm = this;
        var params = {
          pageNo: vm.pageNo,
          pageSize: 20
        };
        vm.loading = true;
        vm.$http({
          type: "get",
          url: "".concat(vm.$config.base.PROBE_BASE_URL, "scrapProbe/list"),
          params: params,
          except: true
        }).then(function (res) {
          if (!res.data) {
            (0, _toast.default)("网络错误");
            return;
          }

          vm.loading = false;
          res.data.data.map(function (item) {
            item.time = (0, _time.getYMD)(item.uploadAt);
          });
          vm.list = vm.list.concat(res.data.data);
          vm.isShowAll = res.data.datalength < 20;
        });
      },
      loadMore: function loadMore() {
        var vm = this;
        vm.pageNo = vm.pageNo + 1;
        vm.loadList();
      },
      createItem: function createItem() {
        this.$router.push({
          path: './addUploadImgPage'
        });
      }
    }
  };
  _exports.default = _default;
});